import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "notificationText"]

  initialize() {
    this.resetState = this.resetState.bind(this)
  }

  copy(e) {
    e.preventDefault()
    navigator.clipboard.writeText(this.element.href)
    this.notify()
  }

  notify() {
    this.textTarget.classList.add("hidden")
    this.notificationTextTarget.classList.remove("hidden")

    setTimeout(this.resetState, 1500)
  }

  resetState() {
    this.textTarget.classList.remove("hidden")
    this.notificationTextTarget.classList.add("hidden")
  }
}
