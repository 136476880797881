import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["section"]

  initialize() {
    this.selectedSection = null
  }

  connect() {
    if (window.location.hash !== "") {
      this.selectedSection = this.sectionTargets.find((section) => section.id === window.location.hash.substr(1))
      if (!this.selectedSection) {
        console.error("[help_show] Could not find current anchor as sectionTarget")
      }

      setTimeout(() => {
        this.showSelected()
      }, 0)
    } else {
      setTimeout(() => {
        this.showSections()
      }, 0)
    }
  }

  showSelected() {
    this.sectionTargets.forEach((target) => {
      const targetController = this.application.getControllerForElementAndIdentifier(target, "reveal")
      if (!targetController) {
        console.error("[help_show] error retrieve controller for section", target)
      }
      if (this.selectedSection.id === target.id) {
        targetController.show()
      } else {
        targetController.hide()
      }
    })
  }

  showSections() {
    this.sectionTargets.forEach((target) => {
      const targetController = this.application.getControllerForElementAndIdentifier(target, "reveal")
      if (!targetController) {
        console.error("[help_show] error retrieve controller for section", target)
      }
      targetController.show()
    })
  }
}
