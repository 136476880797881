import { Controller } from "@hotwired/stimulus"
import "form-request-submit-polyfill"

export default class extends Controller {
  static targets = ["search", "showMore", "showLess"]

  declare readonly searchTarget: HTMLFormElement
  declare readonly hasSearchTarget: boolean
  declare readonly showMoreTarget: HTMLButtonElement
  declare readonly showLesstTarget: HTMLButtonElement
  declare readonly tagsTarget: HTMLElement

  hasTagsOpen: boolean

  initialize() {
    this.hasTagsOpen = false
  }

  showTags(e) {
    e.preventDefault()
    this.element.classList.add("has-tags-open")
  }

  hideTags(e) {
    e.preventDefault()
    this.element.classList.remove("has-tags-open")
  }

  submit() {
    this.showMoreTarget.form.requestSubmit()
  }
}
