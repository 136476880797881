import { Controller } from "@hotwired/stimulus"
import "form-request-submit-polyfill"
import { FetchRequest } from "@rails/request.js"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  initialize() {
    this.onRender = this.onRender.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  connect() {
    this.element.addEventListener("submit", this.onSubmit)
    window.addEventListener("turbo:before-stream-render", this.onRender)
  }

  disconnect() {
    window.removeEventListener("turbo:before-stream-render", this.onRender)
    this.element.removeEventListener("submit", this.onSubmit)
  }

  update() {
    if (this.element.submit) {
      // Push down in the event loop
      setTimeout(() => {
        this.element.requestSubmit()
      }, 0)
    }
  }

  onSubmit(e) {
    e.preventDefault()

    let url = new URL(this.element.action, document.baseURI)
    url.search = decodeURIComponent(new URLSearchParams(this.formData()).toString())

    const request = new FetchRequest("get", url, { responseKind: "turbo-stream" })

    this.showProgressBar()
    request.perform().then(() => {
      this.hideProgressBar()

      Turbo.cache.clear() // Clear Turbo.cache

      // history.replaceState(null, "", url)
      // Source: https://discuss.hotwired.dev/t/back-button-not-working/3012/2
      Turbo.navigator.history.push({ href: url })
      // history.pushState({}, null, url)
    })
  }

  onRender() {
    this.element.querySelectorAll("[type=submit]").forEach((node) => {
      node.disabled = false
    })
  }

  formData() {
    let formData = new FormData(this.element)
    let cleanedFormData = new FormData(this.element)

    for (const key of formData.keys()) {
      const values = formData.getAll(key)

      if (values.length === 0 || values[values.length - 1] === "") {
        cleanedFormData.delete(key)
      } else if (key.endsWith("[]")) {
        // Array concatenation
        cleanedFormData.delete(key)
        cleanedFormData.set(key.slice(0, -2), values.filter((value) => value.trim().length > 1).join(","))
      }
    }

    return cleanedFormData
  }

  showProgressBar() {
    navigator.delegate.adapter.progressBar.setValue(0)
    navigator.delegate.adapter.progressBar.show()
  }

  hideProgressBar() {
    navigator.delegate.adapter.progressBar.setValue(1)
    navigator.delegate.adapter.progressBar.hide()
  }
}
