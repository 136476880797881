import ModalComponent from "../modal_component/modal_component"

export default class extends ModalComponent {
  id = "modal-newsletter"

  constructor(context) {
    super(context)

    let anchor = document.URL.split("#")[1]

    if (anchor !== undefined && anchor == "newsletter") {
      this.show()
    }
  }
}
