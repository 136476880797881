import ModalComponent from "../modal_component/modal_component"

const ESCAPE_KEY = 27
const ARROW_LEFT = 37
const ARROW_RIGHT = 39

export default class extends ModalComponent {
  static targets = ["content", "thumbnail", "arrows"]
  id = "modal-typeface-family-case-study"
  currentTarget: HTMLElement | null
  currentIndex: number | null
  thumbnailTargetsCount: number
  declare readonly contentTarget: Element
  declare readonly thumbnailTarget: Element
  declare readonly thumbnailTargets: Element[]
  declare readonly arrowsTarget: Element

  constructor(context) {
    super(context)
  }

  initialize() {
    super.initialize()
    this.currentTarget = null
    this.currentIndex = null
    this.thumbnailTargetsCount = 0
    this.onKeydown = this.onKeydown.bind(this)
  }

  connect() {
    super.connect()
    this.thumbnailTargetsCount = this.thumbnailTargets.length
    if (this.thumbnailTargetsCount < 2) {
      this.arrowsTarget.classList.add("hidden")
    }
  }

  toggle(evt: MouseEvent) {
    const target = evt.currentTarget as HTMLElement
    this.replaceContent(target)
    super.toggle(evt)
  }

  show() {
    super.show()
    this.registerKeyboardListeners()
  }

  hide() {
    super.hide()
    this.currentTarget = null
    this.currentIndex = null
    this.removeKeyboardListeners()
  }

  // Actions
  previous() {
    const previousTarget = this.thumbnailTargets[this.currentIndex - 1]
    if (previousTarget) {
      return this.replaceContent(previousTarget)
    }
    return this.replaceContent(this.thumbnailTargets[this.thumbnailTargetsCount - 1])
  }

  next() {
    const nextTarget = this.thumbnailTargets[this.currentIndex + 1]
    if (nextTarget) {
      return this.replaceContent(nextTarget)
    }
    return this.replaceContent(this.thumbnailTargets[0])
  }

  replaceContent(target) {
    this.currentIndex = this.thumbnailTargets.findIndex(
      (t) =>
        t.getAttribute("data-family-case-study-image-url") === target.getAttribute("data-family-case-study-image-url")
    )
    this.currentTarget = target

    const imageUrl = target.getAttribute("data-family-case-study-image-url")

    const oldChild = this.contentTarget.children[0]
    const newChild = document.createElement("div")
    const imageNode = document.createElement("img")
    imageNode.src = imageUrl

    newChild.className = "modal-typeface-family-case-study-wrapper"
    newChild.appendChild(imageNode)

    this.contentTarget.replaceChild(newChild, oldChild)
  }

  // Listeners
  registerKeyboardListeners() {
    window.addEventListener("keydown", this.onKeydown)
  }

  removeKeyboardListeners() {
    window.removeEventListener("keydown", this.onKeydown)
  }

  onKeydown(e) {
    if (e.keyCode === ESCAPE_KEY) {
      return this.hide()
    }

    if (e.keyCode === ARROW_LEFT) {
      return this.previous()
    }

    if (e.keyCode === ARROW_RIGHT) {
      return this.next()
    }
  }
}
