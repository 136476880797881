import { Controller } from "@hotwired/stimulus"

import { SwiperOptions } from "swiper"
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper"

export default class extends Controller {
  static values = {
    options: Object,
  }

  declare readonly optionsValue: Object

  swiper: Swiper

  connect(): void {
    this.swiper = new Swiper(this.element as HTMLElement, {
      ...this.defaultOptions,
      ...this.optionsValue,
    })
  }

  disconnect(): void {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions(): SwiperOptions {
    return {
      modules: [Navigation, Pagination, Autoplay, EffectFade],
    }
  }
}
