import { useWindowResize } from "stimulus-use"
import font_size_slider_controller from "./font_size_slider_controller"

export default class extends font_size_slider_controller {
  static targets = ["sample", "input"]

  initialize() {
    super.initialize()
    this.isMobile = window.innerWidth < 1024
    this.dataName = this.isMobile ? "ratio-mobile" : "ratio"
  }

  connect() {
    super.connect()
    useWindowResize(this)

    console.debug("[variation_size_slider] connect")
    this.updateSampleTargetsFontSize(1)
  }

  onChange(position) {
    this.updateSampleTargetsFontSize(position)
  }

  updateSampleTargetsFontSize(position) {
    if (this.hasSampleTarget) {
      this.sampleTargets.map((target) => {
        const ratio = target
          .getAttribute(`data-variation-size-slider-${this.dataName}`)
          .split("-")
          .map((i) => parseInt(i, 10))
        const [sMin, lMin, sMax, lMax] = ratio
        const size = (sMax - sMin) * (position / 100) + sMin
        const line = (lMax - lMin) * (position / 100) + lMin

        target.style.fontSize = `${size}px`
        target.style.lineHeight = `${line}px`
        target.style.height = "5px"
        const scrollHeight = target.scrollHeight
        if (scrollHeight > 0) {
          target.style.height = `${scrollHeight}px`
        } else {
          target.style.height = `auto`
        }
      })
    }
  }

  /* Events */
  windowResize() {
    this.isMobile = window.innerWidth < 1024
  }
}
