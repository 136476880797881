import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  declare readonly modalTarget: Element

  id: string = "modal"
  _isVisible: boolean = false

  constructor(context) {
    super(context)
  }

  toggle(evt: MouseEvent) {
    evt.preventDefault()

    if (this._isVisible) {
      return this.hide()
    }
    return this.show()
  }

  hide() {
    this.modalTarget.classList.remove("is-visible")
    window.document.body.classList.remove("has-modal")
    this._isVisible = false
  }

  show() {
    this.modalTarget.classList.add("is-visible")
    window.document.body.classList.add("has-modal")
    this._isVisible = true
  }

  // disconnect() {}
}
