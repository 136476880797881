import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static values = { opened: Boolean }
  declare openedValue: boolean

  connect() {
    useClickOutside(this)

    if (!this.openedValue) {
      this.close()
    } else {
      this.open()
    }
  }

  // Actions
  open() {
    this.element.classList.add("is-opened")
  }

  close() {
    this.element.classList.remove("is-opened")
  }

  toggle(e: Event) {
    e.stopPropagation()
    e.preventDefault()

    if (this.openedValue) {
      this.close()
    } else {
      this.open()
    }
    this.openedValue = !this.openedValue
  }

  clickOutside(event) {
    this.close()
    this.openedValue = false
  }
}
