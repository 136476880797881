import { Controller } from "@hotwired/stimulus"

const DATA_ID = "data-tabs-id"
const DATA_ACTION = "show"

function queryAction(element: Element, id: string): Element {
  return element.querySelector(`[data-action="click->tabs#show"][data-tabs-id="${id}"]`)
}

export default class extends Controller {
  static targets = ["content"]
  declare readonly contentTarget: Element
  declare readonly contentTargets: Element[]

  static values = { selected: String }
  declare selectedValue: string

  _tabs: Map<string, { header: Element; content: Element }> = new Map()

  get selectedTab(): { header: Element; content: Element } {
    return this._tabs.get(this.selectedValue)
  }

  // Lifecycle
  connect() {
    this.contentTargets.map((content) => {
      const id = content.getAttribute(DATA_ID)
      this._tabs.set(id, {
        header: queryAction(this.element, id),
        content,
      })
    })

    if (!this.selectedValue) {
      this.selectTab(this._tabs.keys().next().value)
    } else {
      this.selectTab(this.selectedValue)
    }

    this.onStickyHeaderUdpdate = this.onStickyHeaderUdpdate.bind(this)

    setTimeout(() => {
      window.addEventListener("sticky-header:update", this.onStickyHeaderUdpdate)
    }, 0)
  }

  disconnect() {
    window.removeEventListener("sticky-header:update", this.onStickyHeaderUdpdate)
  }

  // Events
  onStickyHeaderUdpdate() {
    this.contentTargets.map((content) => {
      const id = content.getAttribute(DATA_ID)
      this._tabs.set(id, {
        header: queryAction(this.element, id),
        content,
      })
    })

    if (this._tabs.has(this.selectedValue)) {
      this.selectedValue = this.selectedValue

      this._tabs.get(this.selectedValue).content.classList.add("selected")
      this._tabs.get(this.selectedValue).header.classList.add("selected")
    }
  }

  // Actions
  show(e: Event) {
    const target = e.currentTarget as HTMLElement
    const id = target.getAttribute(DATA_ID)
    if (target.nodeName === "BUTTON") {
      e.stopPropagation()
      e.preventDefault()
    }
    this.selectTab(id)
  }

  // Methods
  clearSelected() {
    if (this.selectedTab) {
      this.selectedTab.content.classList.remove("selected")
      this.selectedTab.header.classList.remove("selected")
    }
  }

  selectTab(id: string) {
    if (this._tabs.has(id) && this.selectedValue !== id) {
      this.clearSelected()
      window.dispatchEvent(
        new CustomEvent("tabs:update", {
          detail: {
            oldId: this.selectedValue,
            id,
          },
        })
      )
      this.selectedValue = id

      this._tabs.get(id).content.classList.add("selected")
      this._tabs.get(id).header.classList.add("selected")
    }
  }
}
