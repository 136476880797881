import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["box", "subject"]
  static classes = ["!hidden"]

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : "!hidden"
    if (this.hasSubjectTarget) {
      this.toggleHelpBoxes({ currentTarget: this.subjectTarget })
    }
  }

  toggleHelpBoxes(e) {
    const selected_id = e.currentTarget.value

    this.boxTargets.forEach((box) => {
      if (box.id === selected_id) {
        box.classList.remove(this.class)
      } else {
        box.classList.add(this.class)
      }
    })
  }
}
