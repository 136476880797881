import { Controller } from "@hotwired/stimulus"

const PREFIX_SYSTEM = "system"
const PREFIX_VARIATION = "variation"

const WINDOWS_FONTS = {
  sans: [
    { text: "Century Gothic", value: "font-century-gothic" },
    { text: "Gill Sans Nova", value: "font-gill-sans-nova" },
    { text: "Arial", value: "font-arial" },
    { text: "Verdana", value: "font-verdana" },
  ],
  serif: [
    { text: "Georgia", value: "font-georiga" },
    { text: "Palatino Linotype", value: "font-palatino-linotype" },
    { text: "Constantia", value: "font-constantia" },
    { text: "Times New Roman", value: "font-times-new-roman" },
  ],
}

const MACOS_FONTS = {
  sans: [
    { text: "Futura", value: "font-futura" },
    { text: "Gill Sans", value: "font-gill-sans" },
    { text: "Helvetica Neue", value: "font-helvetica-neue" },
    { text: "Verdana ", value: "font-verdana" },
  ],
  serif: [
    { text: "Big Caslon", value: "font-big-caslon" },
    { text: "Hoefler Text", value: "font-hoefler-text" },
    { text: "Didot", value: "font-didot" },
    { text: "Times ", value: "font-times" },
  ],
}

function fontFamilyToOption({ value, text }, prefix) {
  const option = document.createElement("option")
  option.value = value
  option.text = text
  option.setAttribute("data-prefix", prefix)
  return option
}

type SystemOption = {
  text: string
  value: string
}

type SystemFonts = {
  sans: SystemOption[]
  serif: SystemOption[]
}

function isMacOrIOS(platform) {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(platform) ||
    platform.toLowerCase().indexOf("mac") > -1
  )
}

export default class extends Controller {
  static targets = ["select"]
  static values = { variations: Array, default: String, system: String, segment: String }

  declare readonly selectTarget: HTMLSelectElement
  declare readonly variationsValue: SystemOption[]
  declare readonly defaultValue: string
  declare readonly systemValue: string
  declare readonly segmentValue: string
  // declare dispatch?: (eventName: string, detail: {}) => void

  isMacOS: boolean
  options: HTMLOptionElement[]
  variationsOptions: HTMLOptionElement[]

  get systemFonts(): SystemFonts {
    return this.isMacOS ? MACOS_FONTS : WINDOWS_FONTS
  }

  initialize() {
    this.isMacOS = isMacOrIOS(navigator.platform)

    this.options = [
      ...this.systemFonts.sans.map((font) => fontFamilyToOption(font, PREFIX_SYSTEM)),
      ...this.systemFonts.serif.map((font) => fontFamilyToOption(font, PREFIX_SYSTEM)),
    ]
    this.onChange = this.onChange.bind(this)
  }

  connect() {
    this.variationsOptions = this.variationsValue.map((font) => fontFamilyToOption(font, PREFIX_VARIATION))
    this.replaceOptions()
    this.selectTarget.addEventListener("change", this.onChange)
  }

  disconnect() {
    this.selectTarget.removeEventListener("change", this.onChange)
  }

  /* Methods */
  replaceOptions() {
    const defaultOption = document.createElement("option")
    defaultOption.value = "-1"
    defaultOption.text = this.defaultValue

    const systemOptionGroup = document.createElement("optgroup")
    systemOptionGroup.label = this.systemValue

    const segmentOptionGroup = document.createElement("optgroup")
    segmentOptionGroup.label = this.segmentValue

    this.options.forEach((option) => systemOptionGroup.appendChild(option))
    this.variationsOptions.forEach((variation) => segmentOptionGroup.appendChild(variation))

    this.selectTarget.options.add(defaultOption)
    this.selectTarget.options.add(systemOptionGroup)
    this.selectTarget.options.add(segmentOptionGroup)
  }

  /* Events */
  onChange(e) {
    const option = e.target.selectedOptions[0]
    const value = option.value
    const prefix = option.getAttribute("data-prefix")
    this.dispatch("change", { detail: { value, prefix } })
  }
}
