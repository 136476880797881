import { Controller } from "@hotwired/stimulus"

const GLYPHS = {
  H: "N",
  O: "Q",
  e: "o",
  i: "l",
  N: "H",
  Q: "O",
  o: "e",
  l: "i",
  é: "ë",
  ë: "é",
  ù: "ñ",
  ñ: "ù",
  ",": ".",
  ".": ",",
  "-": "¶",
  "¶": "-",
  "!": "?",
  "?": "!",
  "’": "*",
  "*": "’",
  "–": "/",
  "/": "–",
  $: "€",
  "€": "$",
  0: "8",
  8: "0",
  6: "9",
  9: "6",
  ﬁ: "ﬂ",
  ﬂ: "ﬁ",
}

export default class extends Controller {
  initialize() {
    this._initialText = ""
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onTextPaste = this.onTextPaste.bind(this)
    this.onTextCopy = this.onTextCopy.bind(this)
    this.replaceText = this.replaceText.bind(this)
  }

  connect() {
    this._initialText = this.element.value
    this.element.addEventListener("keydown", this.onKeyDown)
    this.element.addEventListener("paste", this.onTextPaste)
    this.element.addEventListener("copy", this.onTextCopy)
    this.element.addEventListener("cut", this.onTextCopy)
    this.element.addEventListener("replaceText", this.replaceText)

    this.replaceText()
  }

  disconnect() {
    this.element.value = this._initialText
    this.element.removeEventListener("keydown", this.onKeyDown)
    this.element.removeEventListener("paste", this.onTextPaste)
    this.element.removeEventListener("copy", this.onTextCopy)
    this.element.removeEventListener("cut", this.onTextCopy)
    this.element.removeEventListener("replaceText", this.replaceText)
  }

  onKeyDown(e) {
    const target = e.target
    let text = target.value

    if (GLYPHS[e.key]) {
      const start = target.selectionStart
      text = text.substr(0, start) + GLYPHS[e.key] + text.substr(target.selectionEnd)
      target.value = text
      target.setSelectionRange(start + 1, start + 1)
      e.preventDefault()
    }

    setTimeout(() => {
      this.dispatch("update", { detail: { text } })
    })
  }

  onTextCopy(e) {
    e.preventDefault()
    const selection = document.getSelection().toString()
    let swaped = ""
    for (let i = selection.length - 1; i >= 0; i--) {
      const char = GLYPHS[selection[i]] ? GLYPHS[selection[i]] : selection[i]
      swaped = char + swaped
    }
    e.clipboardData.setData("text/plain", swaped)
  }

  onTextPaste(e) {
    const target = e.target
    let text = target.value

    let data = e.clipboardData.getData("Text")
    const start = target.selectionStart
    const end = target.selectionEnd
    for (let i = data.length - 1; i >= 0; i--) {
      if (GLYPHS[data[i]]) {
        data = data.substr(0, i) + GLYPHS[data[i]] + data.substr(i + 1)
      }
    }

    text = text.substr(0, start) + data + text.substr(end)
    target.value = text
    const setPos = start + data.length
    target.setSelectionRange(setPos, setPos)

    e.preventDefault()
    setTimeout(() => {
      this.dispatch("update", { detail: { text } })
    }, 0)
  }

  replaceText() {
    let text = this.element.value
    if (text !== "" && text.length >= 1 && !this.element.classList.contains("font-swapped")) {
      let newText = Array.from(text)
      this.element.value = newText.map((t) => (GLYPHS[t] ? GLYPHS[t] : t)).join("")
      newText = null
    } else {
      this.element.classList.remove("font-swapped")
    }
  }
}
