import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    state: Boolean,
  }

  connect() {
    console.debug("connect expan")
  }

  toggle() {
    this.stateValue = !this.stateValue
  }
}
