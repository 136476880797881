import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["empty"]

  declare readonly emptyEmptyTarget: HTMLFormElement
  declare readonly hasEmptyEmptyTarget: boolean

  _format: string

  initialize() {
    this._format = ""
  }

  connect() {
    if (this.hasEmptyEmptyTarget) {
      this._format = this.emptyEmptyTarget.form[this.emptyEmptyTarget.name].value
    }
  }

  toggleRadio(e) {
    const target = e.target
    const inputName = target.name
    const identifier = `_${inputName}`
    const oldValue = this[identifier]
    const value = target.value

    if (oldValue === value) {
      const emptyIdentifier = "emptyTarget"
      this[emptyIdentifier].checked = true
      this[identifier] = ""
    } else {
      this[identifier] = value
    }
  }
}
