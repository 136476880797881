import { Controller } from "@hotwired/stimulus"
import { useWindowResize } from "stimulus-use"

const PREFIX_VARIATION = "variation"

export default class extends Controller {
  static targets = ["compareTexts", "compareFonts", "compareFont", "textarea", "reset"]

  /* Lifecycle */
  connect() {
    useWindowResize(this)

    setTimeout(() => {
      this.resizeTextareas()
    }, 0)
  }

  disconnect() {}

  /* Events */
  windowResize() {
    this.resizeTextareas()
  }

  /* Actions */
  selectChange(e) {
    const { value, prefix } = e.detail
    console.debug("[variation-detail] select", e, value, prefix)

    if (value === "-1") {
      this.showTexts()
    } else {
      this.showFonts(value, prefix)
    }
  }

  /* Methods */
  resizeTextareas() {
    this.textareaTargets.map((target) => {
      target.style.height = "5px"
      const scrollHeight = target.scrollHeight
      if (scrollHeight > 0) {
        target.style.height = `${scrollHeight}px`
      } else {
        target.style.height = `auto`
      }
    })
  }

  showTexts(e) {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault()
    }

    this.compareTextsTarget.classList.remove("hidden")
    this.compareFontsTarget.classList.add("hidden")
    this.resetTarget.classList.add("hidden")
    setTimeout(() => {
      this.resizeTextareas()
    }, 0)
  }

  showFonts(familyCss, prefix) {
    this.compareFontTarget.className = familyCss
    this.compareFontsTarget.classList.remove("hidden")
    this.compareTextsTarget.classList.add("hidden")
    this.resetTarget.classList.remove("hidden")

    if (prefix === PREFIX_VARIATION) {
      this.compareFontTarget.setAttribute("data-controller", "font-swap")
    } else {
      this.compareFontTarget.removeAttribute("data-controller", "font-swap")
    }
    setTimeout(() => {
      this.resizeTextareas()
    }, 0)
  }
}
