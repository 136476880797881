import { Controller } from "@hotwired/stimulus"

import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

export default class extends Controller {
  static values = {
    options: Object,
  }

  initialize() {
    this._tippy = null
  }

  connect() {
    this._tippy = tippy(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
    })
  }

  disconnect() {
    this._tippy.destroy()
  }

  get defaultOptions() {
    return {
      allowHTML: true,
      theme: "tf",
      placement: "bottom",
      maxWidth: 210,
      interactive: true,
    }
  }
}
