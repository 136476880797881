import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static values = { variation: String, format: String }

  initialize() {
    this.isConnected = false
  }

  connect() {
    this.isConnected = true
  }

  updateVariation(evt) {
    const newVariation = evt.currentTarget.value
    if (newVariation !== this.variationValue) {
      this.variationValue = newVariation
    }
  }

  variationValueChanged() {
    this.isConnected && this.updateURL()
  }

  updateFormat(evt) {
    const newFormat = evt.currentTarget.value
    if (newFormat !== this.formatValue) {
      this.formatValue = newFormat
    }
  }

  formatValueChanged() {
    this.isConnected && this.updateURL()
  }

  async updateURL() {
    let url = new URL(window.location)
    let searchParams = new URLSearchParams({ fmt: this.formatValue })
    url.search = decodeURIComponent(searchParams.toString())
    url.pathname = this.variationValue

    const request = new FetchRequest("get", url, {
      responseKind: "turbo-stream",
    })

    this.showProgressBar()
    request.perform().then(() => {
      this.hideProgressBar()

      history.replaceState(null, "", url)
    })
  }

  showProgressBar() {
    navigator.delegate.adapter.progressBar.setValue(0)
    navigator.delegate.adapter.progressBar.show()
  }

  hideProgressBar() {
    navigator.delegate.adapter.progressBar.setValue(1)
    navigator.delegate.adapter.progressBar.hide()
  }
}
