import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "checkboxAll", "input", "variationItem"]
  static values = {
    swapable: Boolean,
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked)
  }

  /* Lifecycle */
  initialize() {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
    this.updateTextareas = this.updateTextareas.bind(this)
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxTargets.forEach((checkbox) => checkbox.addEventListener("change", this.refresh))
    // if (this.swapableValue) {
    //   this.inputTargets.forEach((input) => input.addEventListener("font-swap:update", this.updateTextareas))
    // } else {
    //   this.inputTargets.forEach((input) => input.addEventListener("change", this.updateTextareas))
    // }
    this.debounceRefresh()
  }

  disconnect() {
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.removeEventListener("change", this.toggle)
    this.checkboxTargets.forEach((checkbox) => checkbox.removeEventListener("change", this.refresh))
  }

  /* Children controllers */
  inputTargetConnected(target) {
    if (this.swapableValue) {
      target.addEventListener("font-swap:update", this.updateTextareas)
    } else {
      target.addEventListener("change", this.updateTextareas)
    }
  }
  inputTargetDisconnected(target) {
    if (this.swapableValue) {
      target.removeEventListener("font-swap:update", this.updateTextareas)
    } else {
      target.removeEventListener("change", this.updateTextareas)
    }
  }

  /* Actions */
  toggle(e) {
    e.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = e.target.checked
      const event = new Event("input", { bubbles: false, cancelable: true })
      checkbox.dispatchEvent(event)
    })
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }

  debounceRefresh() {
    setTimeout(() => {
      this.refresh()
    }, 0)
  }

  updateTextareas(e) {
    const target = e.target
    this.inputTargets.forEach((input) => {
      if (input.value !== target.value) {
        input.value = target.value
      }
    })
  }

  select({ params, currentTarget: offer }) {
    const targets = this.checkboxTargets.filter((item) =>
      params.ids.includes(item.getAttribute("data-variation-content-id"))
    )

    targets.forEach((target) => {
      target.checked = offer.checked
      const event = new Event("change", { bubbles: false, cancelable: true })
      target.dispatchEvent(event)
    })

    this.debounceRefresh()
  }
}
