import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sample"]

  connect() {
    this.updateSampleTargetsText = this.updateSampleTargetsText.bind(this)
  }

  onChange(e) {
    const value = e.currentTarget.value

    this.updateSampleTargetsText(value)
  }

  updateSampleTargetsText(value) {
    if (this.hasSampleTarget) {
      const event = new Event("replaceText", { bubbles: false, cancelable: true })
      this.sampleTargets.map((target) => {
        target.value = value
        target.dispatchEvent(event)
      })
    }
  }
}
