import { Controller } from "@hotwired/stimulus"
import "form-request-submit-polyfill"

export default class extends Controller {
  static targets = ["search", "emptyClassification", "emptyWeight", "emptyWidth", "showMore", "showLess"]

  declare readonly searchTarget: HTMLFormElement
  declare readonly emptyClassificationTarget: HTMLFormElement
  declare readonly emptyWeightTarget: HTMLFormElement
  declare readonly emptyWidthTarget: HTMLFormElement
  declare readonly hasSearchTarget: boolean
  declare readonly hasEmptyClassificationTarget: boolean
  declare readonly hasEmptyWeightTarget: boolean
  declare readonly hasEmptyWidthTarget: boolean
  declare readonly tagsTarget: HTMLElement
  declare readonly showMoreTarget: HTMLButtonElement
  declare readonly showLesstTarget: HTMLButtonElement

  hasTagsOpen: boolean
  _classification: string
  _weight: string
  _width: string

  initialize() {
    this.hasTagsOpen = false
    this._classification = ""
    this._weight = ""
    this._width = ""
  }

  connect() {
    if (this.hasEmptyClassificationTarget) {
      this._classification = this.emptyClassificationTarget.form.classification.value
    }
    if (this.hasEmptyClassificationTarget) {
      this._classification = this.emptyClassificationTarget.form.classification.value
    }
    if (this.hasEmptyWeightTarget) {
      this._weight = this.emptyWeightTarget.form.weight.value
    }
    if (this.hasEmptyWidthTarget) {
      this._width = this.emptyWidthTarget.form.width.value
    }
  }

  showTags(e) {
    e.preventDefault()
    this.element.classList.add("has-tags-open")
  }

  hideTags(e) {
    e.preventDefault()
    this.element.classList.remove("has-tags-open")
  }

  toggleRadio(e) {
    const target = e.target
    const inputName = target.name
    const identifier = `_${inputName}`
    const oldValue = this[identifier]
    const value = target.value

    if (oldValue === value) {
      const emptyIdentifier = `empty${inputName.replace(/^\w/, (c) => c.toUpperCase())}Target`
      this[emptyIdentifier].checked = true
      this[identifier] = ""
    } else {
      this[identifier] = value
    }

    this.submit()
  }

  submit() {
    this.showMoreTarget.form.requestSubmit()
  }
}
