import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]
  static classes = ["hidden"]

  connect() {
    this.isRevealed = false
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden"
  }

  toggle() {
    this.isRevealed = !this.isRevealed
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.class)
    })
    this.updateElement()
  }

  show() {
    this.isRevealed = true
    this.itemTargets.forEach((item) => {
      item.classList.remove(this.class)
    })
    this.updateElement()
  }

  hide() {
    this.isRevealed = false
    this.itemTargets.forEach((item) => {
      item.classList.add(this.class)
    })
    this.updateElement()
  }

  updateElement() {
    if (this.isRevealed) {
      this.element.classList.add("has-revealed")
    } else {
      this.element.classList.remove("has-revealed")
    }
  }
}
