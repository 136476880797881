import { Definition } from "@hotwired/stimulus/"

function identifierForContextKey(key: string): string | undefined {
  const logicalName = (key.match(/^(?:\.\/)?(.+)(?:[_-]component\..+?)$/) || [])[1]

  if (logicalName) {
    return logicalName
      .replace(/_/g, "-")
      .replace(/\//g, "--")
      .replace(/--[a-z]+(-[a-z]+)*$/, "")
      .replace(/-component/, "")
  }
}

function definitionsFromContext(context: __WebpackModuleApi.RequireContext): Definition[] {
  return context
    .keys()
    .map(function (key) {
      const identifier = identifierForContextKey(key)

      if (identifier) {
        const controllerConstructor = context(key).default as any

        if (typeof controllerConstructor === "function") {
          return { identifier, controllerConstructor }
        }
      }
    })
    .filter((value) => value) as Definition[]
}

const context = require.context("babel-loader!../components", true, /_component.(js|ts)$/)
const definitions = definitionsFromContext(context)

export default definitions
