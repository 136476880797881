import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { closeAfter: Number }

  declare readonly closeAfterValue: number
  declare readonly hasCloseAfterValue: boolean

  connect() {
    if (this.hasCloseAfterValue) {
      setTimeout(this.close.bind(this), this.closeAfterValue)
    }
  }

  close() {
    this.hide()

    setTimeout(() => {
      this.element.remove()
    }, 300)
  }

  hide() {
    this.element.setAttribute(
      "style",
      "visibility: hidden; opacity: 0; transition: visibility 0s .15s, opacity .15s linear;"
    )
  }
}
