import { Controller } from "@hotwired/stimulus"
import rangeSlider from "rangeslider-pure"

export default class extends Controller {
  static targets = ["sample", "input"]

  initialize() {
    this.sliders = null
    this.onChange = this.onChange.bind(this)
  }

  connect() {
    this.inputTargets.forEach((target) => {
      rangeSlider.create(target, {
        // polyfill: true,     // Boolean, if true, custom markup will be created
        root: document,
        // buffer: null,       // Number, in percent, 0 by default
        // stick: null,        // [Number stickTo, Number stickRadius] : use it if handle should stick to ${stickTo}-th value in ${stickRadius}
        // borderRadius: 10,   // Number, if you're using buffer + border-radius in css
        onSlide: this.onChange,
      })
    })

    this.updateSampleTargetsFontSize = this.updateSampleTargetsFontSize.bind(this)
  }

  disconnect() {
    this.inputTargets.forEach((target) => {
      target.rangeSlider.destroy()
    })
  }

  onChange(position) {
    this.dispatch("change", { detail: { position } })
    this.updateSampleTargetsFontSize(position)
  }

  updateSampleTargetsFontSize(value) {
    if (this.hasSampleTarget) {
      this.sampleTargets.map((target) => {
        let ratio = target.getAttribute("data-font-size-slider-ratio") || 1
        target.style.fontSize = `${value * parseFloat(ratio)}px`
        target.style.lineHeight = `${Math.max(1, 1 * parseFloat(ratio))}em`
      })
    }
  }
}
