import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["price", "originalPrice"]

  updatePrice(e) {
    const target = e.currentTarget
    const price = target.dataset.productItemPrice
    const originalPrice = target.dataset.productItemOriginalPrice

    if (this.hasPriceTarget) {
      this.priceTarget.textContent = price
      this.originalPriceTarget.textContent = originalPrice
    }
  }
}
