import ModalComponent from "../modal_component/modal_component"

const ESCAPE_KEY = 27
const ARROW_LEFT = 37
const ARROW_RIGHT = 39

export default class extends ModalComponent {
  static targets = ["content", "glyph"]
  id = "modal-glyph"
  currentTarget: HTMLElement | null
  currentIndex: number | null
  glyphTargetsCount: number
  declare readonly contentTarget: Element
  declare readonly glyphTarget: Element
  declare readonly glyphTargets: Element[]

  constructor(context) {
    super(context)
  }

  initialize() {
    super.initialize()
    this.currentTarget = null
    this.currentIndex = null
    this.glyphTargetsCount = 0
    this.onKeydown = this.onKeydown.bind(this)
  }

  connect() {
    super.connect()
    this.glyphTargetsCount = this.glyphTargets.length
  }

  toggle(evt: MouseEvent) {
    const target = evt.currentTarget as HTMLElement
    this.replaceContent(target)
    super.toggle(evt)
  }

  show() {
    super.show()
    this.registerKeyboardListeners()
  }

  hide() {
    super.hide()
    this.currentTarget = null
    this.currentIndex = null
    this.removeKeyboardListeners()
  }

  // Actions
  previous() {
    const previousTarget = this.glyphTargets[this.currentIndex - 1]
    if (previousTarget) {
      return this.replaceContent(previousTarget)
    }
    return this.replaceContent(this.glyphTargets[this.glyphTargetsCount - 1])
  }

  next() {
    const nextTarget = this.glyphTargets[this.currentIndex + 1]
    if (nextTarget) {
      return this.replaceContent(nextTarget)
    }
    return this.replaceContent(this.glyphTargets[0])
  }

  replaceContent(target) {
    this.currentIndex = this.glyphTargets.findIndex(
      (t) => t.getAttribute("data-glyph-name") === target.getAttribute("data-glyph-name")
    )
    this.currentTarget = target

    const unicodeName = target.getAttribute("data-glyph-unicode-name")
    const unicode = target.getAttribute("data-glyph-unicode")
    const name = target.getAttribute("data-glyph-name")

    const svgNode = target.querySelector("svg").cloneNode(true)

    const oldChild = this.contentTarget.children[0]
    const newChild = document.createElement("div")
    const infoWrapper = document.createElement("div")
    const nameNode = document.createElement("p")
    const unicodeNameNode = document.createElement("p")

    newChild.className = "glyph-wrapper"
    infoWrapper.className = "svg-info"

    nameNode.textContent = `${name} unicode ${unicode}`
    unicodeNameNode.textContent = unicodeName
    unicodeNameNode.className = "text text-grey-dark"

    newChild.appendChild(svgNode)
    infoWrapper.appendChild(nameNode)
    infoWrapper.appendChild(unicodeNameNode)
    newChild.appendChild(infoWrapper)

    this.contentTarget.replaceChild(newChild, oldChild)
  }

  // Listeners
  registerKeyboardListeners() {
    window.addEventListener("keydown", this.onKeydown)
  }

  removeKeyboardListeners() {
    window.removeEventListener("keydown", this.onKeydown)
  }

  onKeydown(e) {
    if (e.keyCode === ESCAPE_KEY) {
      return this.hide()
    }

    if (e.keyCode === ARROW_LEFT) {
      return this.previous()
    }

    if (e.keyCode === ARROW_RIGHT) {
      return this.next()
    }
  }
}
